import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-credit-cards/es/styles-compiled.css';
import './Payment.css';
import { PaymentForm } from './PaymentForm';
import { Modalpopup } from '../Modalpopup';
import { notify } from '../CommonNotification/CommonNotification';
import { history } from '../../history';
import i18n from 'i18next';
import debounce from 'lodash/debounce';
import Cookies from 'universal-cookie';
import regionList from '../../data/regions.json';
const cookies = new Cookies();
export class PaymentView extends Component {
  state = {
    amount: 0,
    showModal: false,
    modalDetails: null,
    coupon: '',
    planChecked: '',
    planSelected: '',
    disableCouponApply: false,
    restrictKey: false,
    resetLocation: {},
    // countryValue: [],
    regionValue: 'Europe',
    regionValue2: regionList[0],
    submitDisabled: false,
  };

  constructor() {
    super();
    this.countryChange = debounce(this.countryChange, 1000);
  }

  retriveModalDetails = () => {
    const modalBody = (
      <div>
        <p className='red'>
          {i18n.t('SCWWINFO1')}{' '}
          <Link
            to={{
              pathname:
                'https://liveu.zendesk.com/hc/en-us/articles/4406292534803-About-the-Solo-Connect-Regional-Data-Packages',
            }}
            target='_blank'
          >
            {i18n.t('SCWWINFO2')}
          </Link>{' '}
          {i18n.t('SCWWINFO3')}
        </p>
      </div>
    );
    const modalHeader = i18n.t('SCWW');
    this.setState({
      showModal: true,
      modalDetails: {
        isOpen: true,
        classModal: '',
        modalHeader: modalHeader,
        modalBody: modalBody,
        toggle: this.cancel,
        secondaryBtnClick: this.cancel,
        primaryBtnClick: this.cancel,
      },
    });
  };
  showHideLoader = () => {
    this.setState({
      submitDisabled: !this.state.submitDisabled,
    });
  };

  cancel = () => {
    this.setState({
      modalDetails: { isOpen: false },
    });
  };

  onCouponChangeHandler = (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({ coupon: value });
  };
  regionChange = async (region) => {
    try {
      await this.regionCountryOptions(region.value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  regionCountryOptions = async (value) => {
    const {
      plans: { plan1 },
      percent_off,
    } = this.props;
    let filteredPlans;
    const plantype = this.state.planSelected.interval;
    switch (value) {
      case 'Europe':
        // eslint-disable-next-line array-callback-return
        filteredPlans = plan1.filter((plan) => {
          if (plan.id.split('-')[1] === 'Europe') return plan;
        });
        if (percent_off) {
          this.setState(
            {
              regionValue2: regionList[0],
              regionValue: 'Europe',
              planChecked:
                plantype === 'year' ? filteredPlans[1].id : filteredPlans[0].id,
              amount:
                plantype === 'year'
                  ? filteredPlans[1].amount
                  : filteredPlans[0].amount,
              planSelected:
                plantype === 'year' ? filteredPlans[1] : filteredPlans[0],
            },
            () => {
              this.getPlanButton();
            },
          );
        } else {
          this.setState(
            {
              regionValue2: regionList[0],
              regionValue: 'Europe',
              planChecked:
                plantype === 'year' ? filteredPlans[1].id : filteredPlans[0].id,
              amount:
                plantype === 'year'
                  ? filteredPlans[1].amount
                  : filteredPlans[0].amount,
              planSelected:
                plantype === 'year' ? filteredPlans[1] : filteredPlans[0],
            },
            () => {
              this.getPlanButton();
            },
          );
        }
        break;
      case 'Canada':
        // eslint-disable-next-line array-callback-return
        filteredPlans = plan1.filter((plan) => {
          if (plan.id.split('-')[1] === 'Canada') return plan;
        });
        if (percent_off) {
          this.setState(
            {
              regionValue2: regionList[2],
              regionValue: 'Canada',
              planChecked:
                plantype === 'year' ? filteredPlans[1].id : filteredPlans[0].id,
              amount:
                plantype === 'year'
                  ? filteredPlans[1].amount
                  : filteredPlans[0].amount,
              planSelected:
                plantype === 'year' ? filteredPlans[1] : filteredPlans[0],
            },
            () => {
              this.getPlanButton();
            },
          );
        } else {
          this.setState(
            {
              regionValue2: regionList[2],
              regionValue: 'Canada',
              planChecked:
                plantype === 'year' ? filteredPlans[1].id : filteredPlans[0].id,
              amount:
                plantype === 'year'
                  ? filteredPlans[1].amount
                  : filteredPlans[0].amount,
              planSelected:
                plantype === 'year' ? filteredPlans[1] : filteredPlans[0],
            },
            () => {
              this.getPlanButton();
            },
          );
        }
        break;
      case 'Asia':
        // eslint-disable-next-line array-callback-return
        filteredPlans = plan1.filter((plan) => {
          if (plan.id.split('-')[1] === 'Asia') return plan;
        });
        if (percent_off) {
          this.setState(
            {
              regionValue2: regionList[1],
              regionValue: 'Asia',
              planChecked:
                plantype === 'year' ? filteredPlans[1].id : filteredPlans[0].id,
              amount:
                plantype === 'year'
                  ? filteredPlans[1].amount
                  : filteredPlans[0].amount,
              planSelected:
                plantype === 'year' ? filteredPlans[1] : filteredPlans[0],
            },
            () => {
              this.getPlanButton();
            },
          );
        } else {
          this.setState(
            {
              regionValue2: regionList[1],
              regionValue: 'Asia',
              planChecked:
                plantype === 'year' ? filteredPlans[1].id : filteredPlans[0].id,
              amount:
                plantype === 'year'
                  ? filteredPlans[1].amount
                  : filteredPlans[0].amount,
              planSelected:
                plantype === 'year' ? filteredPlans[1] : filteredPlans[0],
            },
            () => {
              this.getPlanButton();
            },
          );
        }
        break;
      default:
        // eslint-disable-next-line array-callback-return
        filteredPlans = plan1.filter((plan) => {
          if (plan.id.split('-')[1] === 'Europe') return plan;
        });
        this.setState(
          {
            regionValue2: regionList[0],
            regionValue: 'Europe',
            planChecked: filteredPlans[0].id,
            amount: filteredPlans[0].amount,
            planSelected: filteredPlans[0],
          },
          () => {
            this.getPlanButton();
          },
        );
    }
  };
  applycoupon = () => {
    //e.preventDefault();
    const {
      match,
      history: {
        location: { state },
      },
    } = this.props;
    this.setState({ restrictKey: false });
    if (match.url.includes('soloconnect')) {
      if (this.state.coupon.substring(0, 4) === 'LRT_') {
        this.setState({ restrictKey: 'RESTRICTLRT' });
      } else if (
        this.state.coupon.substring(0, 5) === 'SC2M_' &&
        // eslint-disable-next-line eqeqeq
        state.kc == 3
      ) {
        this.setState({ restrictKey: 'RESTRICTSC3M' });
      } else if (
        this.state.coupon.substring(0, 5) === 'SC3M_' &&
        // eslint-disable-next-line eqeqeq
        state.kc == 2
      ) {
        this.setState({ restrictKey: 'RESTRICTSC2M' });
      } else {
        this.couponverify();
        // if (
        //   this.getCouponPrefix() &&
        //   this.state.planSelected.interval === 'month'
        // ) {
        //   const { plan1 } = this.props.plans;
        //   let selectedplan = [...plan1].find(
        //     (plan) => plan.interval === 'year',
        //   );
        //   this.setState(
        //     {
        //       planChecked: selectedplan.id,
        //       amount: selectedplan.amount,
        //       planSelected: selectedplan,
        //     },
        //     () => {
        //       this.couponverify();
        //     },
        //   );
        // } else {
        //   this.couponverify();
        // }
      }
    } else {
      if (
        this.state.coupon.substring(0, 5) === 'SC2M_' ||
        this.state.coupon.substring(0, 5) === 'SC3M_'
      ) {
        this.setState({ restrictKey: 'RESTRICTSOLOCONNECT' });
      } else {
        this.couponverify();
        // if (
        //   this.getCouponPrefix() &&
        //   this.state.planSelected.interval === 'month'
        // ) {
        //   const { plan1 } = this.props.plans;
        //   let selectedplan = [...plan1].find(
        //     (plan) => plan.interval === 'year',
        //   );
        //   this.setState(
        //     {
        //       planChecked: selectedplan.id,
        //       amount: selectedplan.amount,
        //       planSelected: selectedplan,
        //     },
        //     () => {
        //       this.couponverify();
        //     },
        //   );
        // } else {
        //   this.couponverify();
        // }
      }
    }
  };

  couponverify = () => {
    const { verifyCoupon } = this.props;
    this.setState({ disableCouponApply: true });
    verifyCoupon(this.state.coupon, (err, res) => {
      if (res) {
        if (!res.valid) {
          this.setState({ coupon: '', disableCouponApply: false });
          notify('error', i18n.t('COUPONEXPIRED'));
        } else if (
          res.valid &&
          res.metadata &&
          res.metadata.user === cookies.get('username')
        ) {
          this.setState({ coupon: '', disableCouponApply: false });
          notify('error', i18n.t('INVALIDCOUPON'));
        } else {
          this.setState({ disableCouponApply: false });
          notify('success', i18n.t('COUPONAPPLY'));
        }
      }
      if (err) {
        this.setState({ coupon: '', disableCouponApply: false });
        notify('error', i18n.t('INVALIDCOUPON'));
      }
    });
  };
  removeCouponHandler = (e) => {
    e.preventDefault();
    const { removeCoupon } = this.props;
    removeCoupon(() => {
      this.setState({ coupon: '' });
    });
  };

  setPlan = (id) => {
    const { percent_off } = this.props;
    let selectedplan = [];
    const { plan1, plan2 } = this.props.plans;
    if (plan1 && plan2) {
      selectedplan = [...plan1, ...plan2].find((plan) => plan.id === id);
    } else if (plan1) {
      selectedplan = [...plan1].find((plan) => plan.id === id);
    }
    if (
      this.state.coupon &&
      this.getCouponPrefix() &&
      selectedplan.interval === 'month' &&
      percent_off !== 100
    ) {
      const { removeCoupon } = this.props;
      removeCoupon(() => {
        this.setState({ coupon: '' });
      });
    }
    this.setState({
      planChecked: id,
      amount: selectedplan.amount,
      planSelected: selectedplan,
    });
  };
  componentDidMount() {
    const {
      plans: { plan1 },
      history: {
        location: { state },
      },
    } = this.props;
    state && this.setState({ resetLocation: state });
    if (state && state.region === 'ROW') {
      // eslint-disable-next-line array-callback-return
      const filteredPlans = plan1.filter((plan) => {
        if (plan.id.split('-')[1] === 'Europe') return plan;
      });
      this.regionCountryOptions('Europe')
        .then(() => {
          this.setState({
            planChecked: filteredPlans[0].id,
            amount: filteredPlans[0].amount,
            planSelected: filteredPlans[0],
          });
          // this.setState({ countryValue: res });
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    } else {
      this.setState({
        planChecked: plan1[0].id,
        amount: plan1[0].amount,
        planSelected: plan1[0],
      });
    }
  }
  componentDidUpdate(prevProps) {
    const {
      history: {
        location: { state },
      },
      amount_off,
    } = this.props;
    // if (
    //   this.props.amount_off !== prevProps.amount_off ||
    //   this.props.percent_off !== prevProps.percent_off
    // ) {
    //   if (this.state.planSelected.interval === 'month') {
    //     // const { plan1 } = this.props.plans;
    //     // let selectedplan = [...plan1].find((plan) => plan.interval === 'month');
    //     // this.setState({
    //     //   planChecked: selectedplan.id,
    //     //   amount: selectedplan.amount,
    //     //   planSelected: selectedplan,
    //     // });
    //   }
    // }
    if (
      this.props.amount_off !== prevProps.amount_off ||
      this.props.percent_off !== prevProps.percent_off
    ) {
      if (
        amount_off / 100 === 45 ||
        amount_off / 100 === 435 ||
        amount_off / 100 === 295
      ) {
        const { plan1 } = this.props.plans;
        let selectedplan = [...plan1].find((plan) => plan.interval === 'month');
        this.setState({
          planChecked: selectedplan.id,
          amount: selectedplan.amount,
          planSelected: selectedplan,
        });
      } else if (
        this.state.coupon.substring(0, 4) === 'LRT_' ||
        this.state.coupon.substring(0, 5) === 'SC2M_' ||
        this.state.coupon.substring(0, 5) === 'SC3M_'
      ) {
        const { plan1 } = this.props.plans;
        let selectedplan = [...plan1].find((plan) => plan.interval === 'year');
        this.setState({
          planChecked: selectedplan.id,
          amount: selectedplan.amount,
          planSelected: selectedplan,
        });
      }
    }

    if (!state) {
      history.push({
        state: this.state.resetLocation,
      });
    }
  }
  countryChange = (country, zip) => {
    const { tax } = this.props;
    if (country.value === 'US' || country.value === 'CA') {
      if (zip !== '') {
        this.props.calculateTax(zip, country.value);
      }
    } else if (tax) {
      this.props.calculateTax();
    }
  };
  // submitting the form
  onSubmitHandler = async (token, value) => {
    const {
      proceed,
      tax,
      customerAddress,
      history: {
        location: { state },
      },
      unit,
      subscriptions,
      provideCredit,
      setInitialDataLoadedFlag,
      // userLoyaltyCredit,
    } = this.props;

    const { bossId } = this.props.match.params;
    value.tax = tax;
    // value.regionDropDown = value.regionDropDown ? value.regionDropDown.value : '';
    value.scnumber = state.scnumber && state.scnumber.toUpperCase();
    value.kc = state.kc;
    value.bossId = bossId;
    value.payableAmount =
      parseFloat(this.getPayableAmount()) + parseFloat(this.getTax());

    let sub = [];
    if (subscriptions?.lrt) {
      sub.push(...subscriptions?.lrt);
    }
    if (subscriptions?.soloconnect) {
      sub.push(...subscriptions?.soloconnect);
    }

    // let sub = [...subscriptions?.lrt, ...subscriptions?.soloconnect];

    let data = null;
    if (unit?.unit?.product === 'LU_SOLO_PRO') {
      data = {
        pro_unit: unit?.unit,
        simple_units: unit?.units.filter(
          (e) =>
            e.product !== 'LU_SOLO_PRO' &&
            sub.some((x) => x?.metadata?.unit_id === e?.BOSSID),
        ),
      };
    }

    const result = await proceed(token, value, this.state, customerAddress);
    if (result) {
      if (data) {
        await provideCredit(data);
      }
      if (this.state.planSelected.name === 'streamTool') {
        setInitialDataLoadedFlag(bossId, false);
      }

      notify('success', i18n.t('PLANSUBSCRIBED'));
      history.push('/dashboard/units/' + bossId);
    }
  };

  getCouponPrefix = () => {
    const { amount_off, match, percent_off } = this.props;
    if (
      amount_off / 100 === 45 ||
      amount_off / 100 === 450 ||
      amount_off / 100 === 295 ||
      amount_off / 100 === 2950 ||
      amount_off / 100 === 435 ||
      amount_off / 100 === 4350 ||
      percent_off === 100
    ) {
      if (
        (amount_off / 100 === 45 || amount_off / 100 === 450) &&
        match.url.includes('soloconnect')
      ) {
        return false;
      } else if (
        (amount_off / 100 === 435 ||
          amount_off / 100 === 4350 ||
          amount_off / 100 === 295 ||
          amount_off / 100 === 2950) &&
        match.url.includes('lrt')
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
    // return (
    //   coupon.substring(0, 4) === 'LRT_' ||
    //   coupon.substring(0, 5) === 'SC2M_' ||
    //   coupon.substring(0, 5) === 'SC3M_'
    // );
  };

  getPlanRow = (plan) => {
    const {
      t,
      amount_off,
      match,
      // isSoloConnect
    } = this.props;
    if (plan.length) {
      return (
        <>
          <React.Fragment>
            {amount_off / 100 === 45 && match.url.includes('lrt') ? (
              <div className='plan customRadio'>
                <input
                  name='plangrp'
                  onClick={() => this.setPlan(plan[0].id)}
                  checked={this.state.planChecked === plan[0].id ? true : false}
                  value={plan[0]}
                  type='radio'
                />
                <span>
                  {t('MONTHLY')}{' '}
                  {plan[0].metadata.seconds
                    ? `(${plan[0].metadata.seconds / 3600} ${t('HOURS')})`
                    : ''}
                </span>
              </div>
            ) : (
              <>
                {this.state.coupon.substring(0, 4) !== 'LRT_' &&
                  this.state.coupon.substring(0, 5) !== 'SC2M_' &&
                  this.state.coupon.substring(0, 5) !== 'SC3M_' && (
                    <div className='plan customRadio'>
                      <input
                        name='plangrp'
                        onClick={() => this.setPlan(plan[0].id)}
                        checked={
                          this.state.planChecked === plan[0].id ? true : false
                        }
                        value={plan[0]}
                        type='radio'
                      />
                      <span>
                        {t('MONTHLY')}{' '}
                        {plan[0].metadata.seconds
                          ? `(${plan[0].metadata.seconds / 3600} ${t('HOURS')})`
                          : ''}
                      </span>
                    </div>
                  )}

                {/* {(plan[0]?.metadata?.service_type === 'streamtool' &&
                  isSoloConnect?.plan?.interval !== 'month') ||
                plan[0]?.metadata?.service_type !== 'streamtool' ? ( */}
                <div className='plan customRadio radiobtn-responsive'>
                  <input
                    name='plangrp'
                    onClick={() => this.setPlan(plan[1].id)}
                    value={plan[1]}
                    checked={
                      this.state.planChecked === plan[1].id ? true : false
                    }
                    type='radio'
                  />
                  <span>
                    {t('YEARLY')}{' '}
                    {plan[0].metadata.seconds
                      ? `(${plan[0].metadata.seconds / 3600} ${t('HOURS')})`
                      : ''}
                  </span>
                </div>
                {/* ) : null} */}
              </>
            )}
          </React.Fragment>

          {/* <React.Fragment>
            {this.isCouponApplied() &&
              this.getCouponPrefix() &&
              (amount_off / 100 === 45 ||
                amount_off / 100 === 435 ||
                amount_off / 100 === 295) && (
                <div className='plan customRadio'>
                  <input
                    name='plangrp'
                    onClick={() => this.setPlan(plan[0].id)}
                    checked={
                      this.state.planChecked === plan[0].id ? true : false
                    }
                    value={plan[0]}
                    type='radio'
                  />
                  <span>
                    {t('MONTHLY')}{' '}
                    {plan[0].metadata.seconds
                      ? `(${plan[0].metadata.seconds / 3600} ${t('HOURS')})`
                      : ''}
                  </span>
                </div>
              )}
            {this.isCouponApplied() &&
              this.getCouponPrefix() &&
              (amount_off / 100 === 450 ||
                amount_off / 100 === 4350 ||
                amount_off / 100 === 2950) && (
                <div className='plan customRadio'>
                  <input
                    name='plangrp'
                    onClick={() => this.setPlan(plan[1].id)}
                    value={plan[1]}
                    checked={
                      this.state.planChecked === plan[1].id ? true : false
                    }
                    type='radio'
                  />
                  <span>
                    {t('YEARLY')}{' '}
                    {plan[0].metadata.seconds
                      ? `(${plan[0].metadata.seconds / 3600} ${t('HOURS')})`
                      : ''}
                  </span>
                </div>
              )}
          </React.Fragment> */}
        </>
      );
    }
  };

  getPlanButton = () => {
    const {
      t,
      plans,
      history: {
        location: { state },
      },
    } = this.props;
    const { plan1, plan2 } = plans;
    if (plan1 && plan2) {
      return (
        <React.Fragment>
          <div className='plan-group'>
            <b>{t('BASIC')}:</b>
            {this.getPlanRow(plan1)}
          </div>
          <div className='plan-group'>
            <b>{t('ADVANCED')}:</b>
            {this.getPlanRow(plan2)}
          </div>
        </React.Fragment>
      );
    } else if (plan1) {
      if (state && state.region === 'ROW') {
        // eslint-disable-next-line array-callback-return
        const filteredPlans = plan1.filter((plan) => {
          if (plan.id.split('-')[1] === this.state.regionValue) return plan;
        });
        return this.getPlanRow(filteredPlans);
      } else {
        return this.getPlanRow(plan1);
      }
    }
  };

  getTax = () => {
    const { amount_off, percent_off, tax } = this.props;
    const { amount } = this.state;
    let netPayableAmt = 0;
    if (amount_off !== null) {
      netPayableAmt = (amount - amount_off) / 100;
    } else if (percent_off !== null) {
      let percent_discount = (amount * percent_off) / 100;
      netPayableAmt = (amount - percent_discount) / 100;
    } else {
      netPayableAmt = (amount - amount_off) / 100;
    }
    return parseFloat((netPayableAmt * tax) / 100).toFixed(2);
  };

  getPayableAmount = () => {
    const { amount_off, percent_off } = this.props;
    const { amount } = this.state;
    let netPayableAmt = 0;
    if (amount_off !== null) {
      netPayableAmt = (amount - amount_off) / 100;
    } else if (percent_off !== null) {
      let percent_discount = (amount * percent_off) / 100;
      netPayableAmt = (amount - percent_discount) / 100;
    } else {
      netPayableAmt = (amount - amount_off) / 100;
    }
    return parseFloat(netPayableAmt).toFixed(2);
  };
  isCouponApplied = () => {
    const { amount_off, percent_off } = this.props;
    if (amount_off || percent_off) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      amount,
      disableCouponApply,
      restrictKey,
      modalDetails,
      showModal,
      submitDisabled,
      planSelected,
    } = this.state;
    const {
      t,
      match,
      currency,
      customerCardDetails,
      customerAddress,
      customer,
      amount_off,
      percent_off,
      tax,
      isZipValid,
      taxLoading,
      history: {
        location: { state },
      },
      customerDetails,
      isIndianCustomer,
      addCard,
      unit,
    } = this.props;

    return (
      <>
        {showModal && <Modalpopup isOpen={true} modalDetails={modalDetails} />}
        <PaymentForm
          t={t}
          match={match}
          onSubmitHandler={this.onSubmitHandler}
          regionChange={this.regionChange}
          customerCardDetails={customerCardDetails}
          customerAddress={customerAddress}
          getPayableAmount={this.getPayableAmount}
          getTax={this.getTax}
          tax={tax}
          currency={currency}
          amount={amount}
          amount_off={amount_off}
          percent_off={percent_off}
          countryChange={this.countryChange}
          isCouponApplied={this.isCouponApplied}
          getPlanButton={this.getPlanButton}
          coupon={this.state.coupon}
          onCouponChangeHandler={this.onCouponChangeHandler}
          applycoupon={this.applycoupon}
          removeCouponHandler={this.removeCouponHandler}
          kitConfig={state ? state.kc : null}
          customer={customer}
          disableCouponApply={disableCouponApply}
          isZipValid={isZipValid}
          taxLoading={taxLoading}
          licenceKey={this.getCouponPrefix}
          restrictKey={restrictKey}
          state={state}
          regionValue2={this.state.regionValue2}
          regionList={regionList}
          retriveModalDetails={this.retriveModalDetails}
          customerDetails={customerDetails}
          showHideLoader={this.showHideLoader}
          submitDisabled={submitDisabled}
          isIndianCustomer={isIndianCustomer}
          addCard={addCard}
          interval={planSelected}
          scnumber={state?.scnumber}
          unit={unit}
        />
      </>
    );
  }
}
